// Translation
import { useTranslation } from 'react-i18next'
// Styles
import './css/ContactToBook.scss'
// Assets
import EmailGrn from '../../../asset/EmailGrn.png'
import FBGrn from '../../../asset/FBGrn.png'
import PhoneGrn from '../../../asset/PhoneGrn.png'

export default function ContactToBook() {
    const [t] = useTranslation()
    return (
        <section className='ContactToBook'>
            <h1 className='ContactToBookHead'>{t('contact.contactToBook')}</h1>
            <ul className='ContactToBookList'>
                <li className='ContactToBookListItem'>
                    <a className="ContactToBookLink" href='mailto:wioletta.fafera@fenixtherapy.com'>
                        <img className='Email' src={EmailGrn} alt='Email'></img>
                    </a>
                    <a className="ContactToBookLink" href='mailto:wioletta.fafera@fenixtherapy.com'>
                        <p className="ContactToBookText EmailText" >wioletta.fafera@fenixtherapy.com</p>
                    </a>
                </li>
                <li className='ContactToBookListItem'>
                    <a className="ContactToBookLink" href='https://www.facebook.com/WFenixTherapy/' target="_blank" rel="noopener noreferrer">
                        <img className='FB' src={FBGrn} alt='Facebook'></img>
                    </a>
                    <a className="ContactToBookLink" href='https://www.facebook.com/WFenixTherapy/' target="_blank" rel="noopener noreferrer">
                        <p className="ContactToBookText FBText" >WFenixTherapy</p>
                    </a>
                </li>
                <li className='ContactToBookListItem'>
                    <a className="ContactToBookLink" href="tel:+447587481917">
                        <img className='Phone' src={PhoneGrn} alt='Phone'></img>
                    </a>
                    <a className="ContactToBookLink" href="tel:+447587481917">
                        <p className="ContactToBookText PhoneText" >+44 758 748 1917</p>
                    </a>
                </li>
                <li className='ContactToBookListItem'>
                    {/* <a className="ContactToBookLink" href="https://www.google.com/maps/dir//4a+Park+Rd,+Wellingborough+NN8+4PG/@52.3011712,-0.7006461,15.22z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4877a1658369e4d9:0x38206ca4f1ffd42!2m2!1d-0.6919635!2d52.3031809!3e0" target="_blank" rel="noopener noreferrer">
                    <img className='MapsNavi' src={MapsGrn} alt='Address'></img>
                </a>
                 <a className="ContactToBookLink" href="https://www.google.com/maps/dir//4a+Park+Rd,+Wellingborough+NN8+4PG/@52.3011712,-0.7006461,15.22z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4877a1658369e4d9:0x38206ca4f1ffd42!2m2!1d-0.6919635!2d52.3031809!3e0" target="_blank" rel="noopener noreferrer">
                    <p className="ContactToBookText MapsNaviText" >7 Dexters Chambers</p>
                    <p className="ContactToBookText MapsNaviText" >4a Park Road</p>
                    <p className="ContactToBookText MapsNaviText" >NN8 4PG Wellingborough</p>
                </a>1 */}
                </li>
            </ul>
        </section>
    )
}