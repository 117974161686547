// Translation
import { useTranslation } from 'react-i18next'
// Styles
import './css/ServiceSection.scss'

export default function ServiceSection() {
    const [t] = useTranslation()
    return (
        <section className='ServiceSection'>
            <ul className='ServicesList'>
                <li><h1 className='TreatmentList'>{t('home.serviceSection.treatmentList')}</h1></li>
                <li><p className='Treatment'>{t('home.serviceSection.depression')}</p></li>
                <li><p className='Treatment'>{t('home.serviceSection.anxiety')}</p></li>
                <li><p className='Treatment'>{t('home.serviceSection.borderline')}</p></li>
                <li><p className='Treatment'>{t('home.serviceSection.obsessive')}</p></li>
                <li><p className='Treatment'>{t('home.serviceSection.PTSD')}</p></li>
                <li><p className='Treatment'>{t('home.serviceSection.selfHarming')}</p></li>
                <li><p className='Treatment'>{t('home.serviceSection.addictions')}</p></li>
                <li><p>.</p></li>
                <li><h2 className='Service'>{t('home.serviceSection.priceList.consultation')}</h2>
                    <h2 className='Price'>{t('home.serviceSection.priceList.consultationPrice')}</h2></li>
                <li><h2 className='Service'>{t('home.serviceSection.priceList.adults')}</h2>
                    <h2 className='Price'>{t('home.serviceSection.priceList.adultsPrice')}</h2></li>
                <li><h2 className='Service'>{t('home.serviceSection.priceList.couples')}</h2>
                    <h2 className='Price'>{t('home.serviceSection.priceList.couplesPrice')}</h2></li>
                <li><h2 className='Service'>{t('home.serviceSection.priceList.children')}</h2>
                    <h2 className='Price'>{t('home.serviceSection.priceList.childrenPrice')}</h2></li>
                <li><p>.</p></li>
                <li><h3 className='OnlineSessions'>{t('home.serviceSection.onlineSessions')}</h3></li>
            </ul>
        </section>
    )
}