// Translation
import { useTranslation } from 'react-i18next'
// Styles
import './css/AvatarSection.scss'

export default function AvatarSection() {
    const [t] = useTranslation()
    return (
        <section className='AvatarSection'>
            <div className='AvatarHeader'>
                <h1 className='AvatarName'>{t('about.avatarSection.avatarName')}</h1>
            </div>
            <div className='AvatarContainer'>
                <p className='AvatarOccupation'>
                    {t('about.avatarSection.avatarOccupation')}
                </p>
                <div className='AvatarDalailama'>
                    <p className='AvatarDalailamaP'>{t('about.avatarSection.avatarDalailama.P1')}</p>
                    <p className='AvatarDalailamaP'>{t('about.avatarSection.avatarDalailama.P2')}</p>
                    <p className='AvatarDalailamaP'>{t('about.avatarSection.avatarDalailama.P3')}</p>
                    <p className='AvatarDalailamaP'>{t('about.avatarSection.avatarDalailama.P4')}</p>
                </div>
            </div>
        </section>
    )
}