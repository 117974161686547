// Styles
import './css/Maps.scss'

export default function Maps() {
    return (
        <section className="Maps">
             <div className='MapContainer'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2439.706527355298!2d-0.6945384232465258!3d52.3031808720051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4877a1658369e4d9%3A0x38206ca4f1ffd42!2s4a%20Park%20Rd%2C%20Wellingborough%20NN8%204PG!5e0!3m2!1sen!2suk!4v1670701659637!5m2!1sen!2suk" width="600" height="450" allowFullScreen="" loading="lazy" title="Localization" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </section>
    )
}