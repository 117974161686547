// Sections
import AvatarSection from './about/AvatarSection'
import CvSection from './about/CvSection'
// Components
/* import BookingAltLink from "../components/utils/bookingLink/BookingAltLink" */
import ContactUs from "../components/utils/contactUs/ContactUs"
import FooterMini from "../components/utils/footer/FooterMini"


export default function About() {
    return (
        <main className="About">
            <AvatarSection />
            <CvSection />
            {/* <BookingAltLink /> */}
            <ContactUs />
            <FooterMini />
        </main>
    )
}
