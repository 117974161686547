// Styles
import './css/FooterMini.scss'

export default function FooterMini() {
    return (
        <footer id='footerMini'>
            <p>Copyright &copy; 2022 FenixTherapy</p>
        </footer>
    )
}
