// Translation
import { useTranslation } from 'react-i18next'
// Styles
import './css/LogoSection.scss'
// Assets
import FxA from '../../asset/FxA.png'
import FxWideA from '../../asset/FxWideA.png'

export default function LogoSection () {
    const [ t ] = useTranslation()
    return (
        <section className='LogoSection'>
                <div className='LogoArea'>
                    <img src={FxA} alt='Fenix Logo' className='Logo HideOnDesktop' id='FxA' />
                    <img src={FxWideA} alt='Fenix Logo' className='Logo ShowOnDesktop' id='FxWideA' />
                </div>
                <div className='Sentence'>
                    <h1 className='SentenceHeader'>{t('home.logoSection.logoHeader')}</h1>
                    <p className='SentenceParagraph'>{t('home.logoSection.logoParagraph')}</p>
                </div>  
        </section>
    )
}