import { Route, Routes } from 'react-router-dom'
// Navi
import Navi from "./components/navigation/Navi"
// Pages
import Home from './pages/Home'
import About from './pages/About'
/* import Booking from './pages/Booking' */
import Contact from './pages/Contact'


export default function App() {
    return (
        <div className="App">
            <Navi />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />
                {/* <Route path='/booking' element={<Booking />} /> */}
                <Route path='/contact' element={<Contact />} />
                <Route path="*" element={<Home />} />
            </Routes>
        </div>
    )
}