// Translation
import { useTranslation } from 'react-i18next'
// Styles
import './css/ContactUs.scss'
// Assets
import EmailGrn from '../../../asset/EmailGrn.png'
import FBGrn from '../../../asset/FBGrn.png'
import PhoneGrn from '../../../asset/PhoneGrn.png'
import MapsGrn from '../../../asset/MapsGrn.png'

export default function ContactUs() {
    const [ t ] = useTranslation()
    return (
        <div className='ContactUs'>
            <h2 className='ContactUsHead'>{t('contact.contactUs')}</h2>
            <ul className='ContactUsList'>
                <li className='ContactUsListItem'>
                    <a className='ContactUsLink' href='mailto:wioletta.fafera@fenixtherapy.com'>
                        <img className='EmailGrn' src={EmailGrn} alt='wioletta.fafera@fenixtherapy.com'></img>
                    </a>
                </li>
                <li className='ContactUsListItem'>
                    <a className='ContactUsLink' href='https://www.facebook.com/WFenixTherapy' target="_blank" rel="noopener noreferrer">
                        <img className='FBGrn' src={FBGrn} alt='https://www.facebook.com/WFenixTherapy'></img>
                    </a>
                </li>
                <li className='ContactUsListItem'>
                    <a className='ContactUsLink' href="tel:+447587481917">
                        <img className='PhoneGrn' src={PhoneGrn} alt='+44 758 748 1917'></img>
                    </a>
                </li>
                <li className='ContactUsListItem'>
                    <a className='ContactUsLink' href="https://www.google.com/maps/dir//4a+Park+Rd,+Wellingborough+NN8+4PG/@52.3011712,-0.7006461,15.22z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4877a1658369e4d9:0x38206ca4f1ffd42!2m2!1d-0.6919635!2d52.3031809!3e0" target="_blank" rel="noopener noreferrer">
                        <img className='MapsGrn' src={MapsGrn} alt='Google Maps Directions'></img>
                    </a>
                </li>
            </ul>
        </div>
    )
}