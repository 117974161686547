import { NavLink } from "react-router-dom"
import { useState } from 'react'
// Translation
import { useTranslation } from 'react-i18next'
// Assets
import flagEN from '../../asset/EN.png'
import flagPL from '../../asset/PL.png'
// Styles
import './css/Navi.scss'

export default function Navigation() {

    const [isOpen, setIsOpen] = useState(false)
    const [navWdth, setNavWdth] = useState()
    const [navShdw, setNavShdw] = useState()

    const menuHandler = prevState => {

        if (isOpen) {
            setNavWdth({ width: '0' })
            setNavShdw({ backgroundColor: 'rgba(0, 0, 0, 0)' })
            setIsOpen(!prevState)
        } else {
            setNavWdth({ width: '50%' })
            setNavShdw({ backgroundColor: 'rgba(0, 0, 0, 0.425)' })
            setIsOpen(!prevState)
        }
    }

    const [t, i18n] = useTranslation()
    const languageHandler = (lang) => {
        i18n.changeLanguage(lang)
    }

    return (
        <nav className="Navigation">
            <div className="SideNav Mobile">
                <div className="NaviUnclicker" style={{ ...navWdth, ...navShdw }} onClick={() => menuHandler(isOpen)}></div>

                <NavLink to='/'><div className="FxIcoWhite" title={t('navigation.home')}></div></NavLink>

                <div className="SideMenu">
                    <div className={`MenuBtn ${isOpen ? "Open" : ""}`} onClick={() => menuHandler(isOpen)}><div className="MenuBtnBurger"></div></div>

                    <div className="SideBar" style={navWdth}>
                        <button className="CloseMenuBtn" title="Close" onClick={() => menuHandler(isOpen)}>
                            <div className={`MenuBtn ${isOpen ? "Open Close" : ""}`}><div className="MenuBtnBurger"></div></div>
                        </button>

                        <NavLink className="NavLinkHome" onClick={isOpen && menuHandler} to='/'>
                            {t('navigation.home')}</NavLink>
                        <NavLink className="NavLinkAbout" onClick={isOpen && menuHandler} to='/about'>
                            {t('navigation.about')}</NavLink>
                        {/* <NavLink className="NavLinkBooking" onClick={isOpen && menuHandler} to='/booking'>
                            {t('navigation.booking')}</NavLink> */}
                        <NavLink className="NavLinkContact" onClick={isOpen && menuHandler} to='/contact'>
                            {t('navigation.contact')}</NavLink>
                        <button className="LangBtn" onClick={() => languageHandler('pl')}>
                            <img className="LangFlag" src={flagPL} alt="Polish translation" /></button>
                        <button className="LangBtn" onClick={() => languageHandler('en')}>
                            <img className="LangFlag" src={flagEN} alt="English translation" /></button>
                    </div>
                </div>
            </div>

            <div className="NavBar Desktop">
                <NavLink to='/'>{t('navigation.home')}</NavLink>
                <NavLink to='/about'>{t('navigation.about')}</NavLink>
                {/* <NavLink to='/booking'>{t('navigation.booking')}</NavLink> */}
                <NavLink to='/contact'>{t('navigation.contact')}</NavLink>
                <button className="LangBtn" onClick={() => languageHandler('pl')}>
                    <img className="LangFlag" src={flagPL} alt="Polish translation" /></button>
                <button className="LangBtn" onClick={() => languageHandler('en')}>
                    <img className="LangFlag" src={flagEN} alt="English translation" /></button>
            </div>
        </nav>
    )
}