// Styles
import './css/ContactInfo.scss'
// Assets
import Email from '../../asset/Email.png'
import FB from '../../asset/FB.png'
import Phone from '../../asset/Phone.png'
import MapsNavi from '../../asset/Maps.png'

export default function ContactInfo() {
    return (
        <section className='ContactInfo'>
            <ul className='ContactInfoList'>
                <li className='ContactInfoListItem'>
                    <a className="ContactInfoLink" href='mailto:wioletta.fafera@fenixtherapy.com'>
                        <img className='Email' src={Email} alt='Email'></img>
                    </a>
                    <a className="ContactInfoLink" href='mailto:wioletta.fafera@fenixtherapy.com'>
                        <p className="ContactInfoText EmailText" >wioletta.fafera@fenixtherapy.com</p>
                    </a>
                </li>
                <li className='ContactInfoListItem'>
                    <a className="ContactInfoLink" href='https://www.facebook.com/WFenixTherapy/' target="_blank" rel="noopener noreferrer">
                        <img className='FB' src={FB} alt='Facebook'></img>
                    </a>
                    <a className="ContactInfoLink" href='https://www.facebook.com/WFenixTherapy/' target="_blank" rel="noopener noreferrer">
                        <p className="ContactInfoText FBText" >WFenixTherapy</p>
                    </a>
                </li>
                <li className='ContactInfoListItem'>
                    <a className="ContactInfoLink" href="tel:+447587481917">
                        <img className='Phone' src={Phone} alt='Phone'></img>
                    </a>
                    <a className="ContactInfoLink" href="tel:+447587481917">
                        <p className="ContactInfoText PhoneText" >+44 758 748 1917</p>
                    </a>
                </li>
                <li className='ContactInfoListItem'>
                    <a className="ContactInfoLink" href="https://www.google.com/maps/dir//4a+Park+Rd,+Wellingborough+NN8+4PG/@52.3011712,-0.7006461,15.22z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4877a1658369e4d9:0x38206ca4f1ffd42!2m2!1d-0.6919635!2d52.3031809!3e0" target="_blank" rel="noopener noreferrer">
                        <img className='MapsNavi' src={MapsNavi} alt='Address'></img>
                    </a>
                    <a className="ContactInfoLink" href="https://www.google.com/maps/dir//4a+Park+Rd,+Wellingborough+NN8+4PG/@52.3011712,-0.7006461,15.22z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4877a1658369e4d9:0x38206ca4f1ffd42!2m2!1d-0.6919635!2d52.3031809!3e0" target="_blank" rel="noopener noreferrer">
                        <p className="ContactInfoText MapsNaviText" >7 Dexters Chambers</p>
                        <p className="ContactInfoText MapsNaviText" >4a Park Road</p>
                        <p className="ContactInfoText MapsNaviText" >NN8 4PG Wellingborough</p>
                    </a>
                </li>
            </ul>
        </section>
    )
}