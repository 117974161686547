// Sections
import ContactHeader from './contact/ContactHeader'
import ContactInfo from './contact/ContactInfo'
// Components
import Maps from '../components/utils/maps/Maps'
/* import BookingLink from '../components/utils/bookingLink/BookingLink' */
import FooterMini from '../components/utils/footer/FooterMini'

export default function Contact() {
    return (
        <main className="Contact">
            <ContactHeader />
            <ContactInfo />
            <Maps />
            {/* <BookingLink /> */}
            <FooterMini />
        </main>
    )
}