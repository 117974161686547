// Translation
import { useTranslation } from 'react-i18next'
// Styles
import './css/ContactHeader.scss'

export default function ContactHeader() {
    const [t] = useTranslation()
    return (
        <section className='ContactHeader'>
            <p className='ContactSubhead'>{t('contact.contactHeader.contactSubhead')}</p>
            <h1 className='ContactHeaderH1'>{t('contact.contactHeader.H1')}</h1>
            <h1 className='ContactHeaderH1' >{t('contact.contactHeader.H2')}</h1>
            <h1 className='ContactHeaderH1' >{t('contact.contactHeader.H3')}</h1>
        </section>
    )
}