// Styles
import './css/EQ.scss'

export default function EQ() {
    return (
        <section className="EQ">
             <div className='EQContainer'>
                <div className="EQframe"></div>
            </div>
        </section>
    )
}