// Sections
import LogoSection from './home/LogoSection'
import ServiceSection from './home/ServiceSection'
// Components
/* import Maps from '../components/utils/maps/Maps' */
/* import BookingLink from '../components/utils/bookingLink/BookingLink' */
import EQ from '../components/utils/contactUs/EQ'
import ContactToBook from '../components/utils/contactUs/ContactToBook'
import FooterSection from '../components/utils/footer/FooterSection'

export default function Home() {
    return (
        <main className="Home">
            <LogoSection />
            <ServiceSection />
            {/* <Maps /> */}
            {/* <BookingLink /> */}
            <EQ />
            <ContactToBook />
            <FooterSection />
        </main>
    )
}