// Maps
import Maps from '../maps/Maps'
// Styles
import './css/FooterSection.scss'
// Assets
import FxA from '../../../asset/FxA.png'
import MapsNav from '../../../asset/Maps.png'

export default function FooterSection() {
    return (
        <footer>
            <div className='footerSection'>
                <div className='footerTop'>
                    <a className="ContactToBookLink" href="https://www.google.com/maps/dir//4a+Park+Rd,+Wellingborough+NN8+4PG/@52.3011712,-0.7006461,15.22z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4877a1658369e4d9:0x38206ca4f1ffd42!2m2!1d-0.6919635!2d52.3031809!3e0" target="_blank" rel="noopener noreferrer">
                        <img className='MapsNav' src={MapsNav} alt='Address'></img>
                    </a>
                    <a className="ContactToBookLink " href="https://www.google.com/maps/dir//4a+Park+Rd,+Wellingborough+NN8+4PG/@52.3011712,-0.7006461,15.22z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4877a1658369e4d9:0x38206ca4f1ffd42!2m2!1d-0.6919635!2d52.3031809!3e0" target="_blank" rel="noopener noreferrer">
                        <p><img src={FxA} className='footerLogo' alt="Fenix Logo" /></p>
                        <p className='footerAddress'>7 Dexters Chambers</p>
                        <p className='footerAddress'>4a Park Road</p>
                        <p className='footerAddress ContactBottom'>NN8 4PG Wellingborough</p>
                        {/* <p className="ContactToBookText MapsNaviText" >7 Dexters Chambers</p>
                        <p className="ContactToBookText MapsNaviText" >4a Park Road</p>
                    <p className="ContactToBookText MapsNaviText" >NN8 4PG Wellingborough</p> */}
                    </a>
                    <Maps />
                    <ul className='footerLinks'>
                        {/* <li><p>Terms of Service</p></li> */}
                        {/* <li><p>Privacy Policy</p></li> */}
                    </ul>
                </div>
            </div>
            <div className='footerBottom'>
                <p>Copyright &copy; 2023 FenixTherapy</p>
                {/* <p>-</p>
                <p>.</p> */}
            </div>
        </footer>
    )
}