// Translation
import { useTranslation } from 'react-i18next'
// Styles
import './css/CvSection.scss'

export default function CvSection() {
    const [t] = useTranslation()
    return (
        <section className='CvSection'>
            <div className="CvHead">
                <h2 className="CvWelcome">{t('about.cvSection.cvWelcome')}</h2>
            </div>
            <div className="CvContent">
                <p>{t('about.cvSection.cvContent.P1')}</p>
                <p>{t('about.cvSection.cvContent.P2')}</p>
                <h2>{t('about.cvSection.cvContent.H1')}</h2>
                <p>{t('about.cvSection.cvContent.P3')}</p>
                <p>{t('about.cvSection.cvContent.P4')}</p>
                <p>{t('about.cvSection.cvContent.P5')}</p>
                <p>{t('about.cvSection.cvContent.P6')}</p>
                <p>{t('about.cvSection.cvContent.P7')}</p>
                <p>{t('about.cvSection.cvContent.P8')}</p>
                <p>{t('about.cvSection.cvContent.P9')}</p>
                <p>{t('about.cvSection.cvContent.P10')}</p>
                <p>{t('about.cvSection.cvContent.P11')}</p>
                <h2>{t('about.cvSection.cvContent.H2')}</h2>
                <p>{t('about.cvSection.cvContent.P12')}</p>
                <p>{t('about.cvSection.cvContent.P13')}</p>
                <p>{t('about.cvSection.cvContent.P14')}</p>
                <p>{t('about.cvSection.cvContent.P15')}</p>
                <p>{t('about.cvSection.cvContent.P16')}</p>
                <p>{t('about.cvSection.cvContent.P17')}</p>
                <p>{t('about.cvSection.cvContent.P18')}</p>
            </div>
        </section>
    )
}